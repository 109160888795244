.mobile-nav-button {
    display: none;
}
.mobile-menu {
    opacity: 1;
    right: 0;
    background: none;
    position: static;
    padding: 0;
}
.mobile-menu ul {
    transform: none;
}
@media only screen and (max-width: 767px) {
body {
  overflow-x: hidden;
}
.mobile-nav-button {
    display: block;
}
.mobile-nav-button {
  width: 35px;
  position: absolute;
  margin: 2rem;
  right: 0;
  top: 168px;
  z-index: 9999;
  cursor: pointer;
  width: 50px;
  height: 30px;
}

.mobile-nav-button .mobile-nav-button__line {
  width: 100%;
  height: 4px;
  background: #ffffff;
  position: relative;
  transition: 1s ease;
  float: right;
}

.mobile-nav-button .mobile-nav-button__line:nth-of-type(2) { margin: 0.5rem 0; }

.mobile-nav-button .mobile-nav-button__line--1 {
  transform: rotate(45deg);
  top: 13px;
  position: absolute;
  width: 75% !important;
    right: 0;
}

.mobile-nav-button .mobile-nav-button__line--2 { display: none; }

.mobile-nav-button .mobile-nav-button__line--3 {
  transform: rotate(135deg);
  top: 13px;
  position: absolute;
  width: 78% !important;
    right: 0
}

.mobile-nav-button .line2 {
  width: 76% !important;
}

.line_1,
.line_2 {
    width: 30px !important
}
.line_3 {
    width: 20px!important
}

.mobile-menu {
  display: block;
  width: 100%;
  right: -100%;
  height: auto;
  position: absolute;
  z-index: 9998;
  transition: 0.6s ease;
  top: 140px;
  opacity: 0;
}

.mobile-menu ul {
  position: relative;
  top: 50%;
  transform: none;
  padding: 0;
}

.mobile-menu ul li { list-style: none; }

.mobile-menu ul li a {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: block;
  text-align: center;
  text-decoration: none;
  color: #0e0e0e;
  font-size: 3rem;
  font-weight: bold;
  overflow: hidden;
  position: relative;
}


.mobile-menu ul li a:hover { color: #fff; }

.mobile-menu ul li a:hover:after { right: 0; }

.mobile-menu img {
  position: absolute;
  width: 150px;
  display: block;
  left: 50%;
  top: 3rem;
  transform: translatex(-50%);
  padding: 0;
  text-align: center;
}

.mobile-menu--open {
  right: 0;
  opacity: 1;
  background-color: rgba(255, 255, 255, .9);
   }
.mobile-menu ul li a:after {
    display: none;
}
}
@media only screen and (max-width: 480px) {
  .mobile-nav-button {
    top: 164px;
  }
}